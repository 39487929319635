import { Avatar, List, Button, Modal, Tag } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import { ZoomInOutlined, CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { get, post } from '../../util/io';

const App = ({isAdmin}) => {
  const navigate = useNavigate();
  const [ listData, setListData ] = useState(undefined);
  const [ modalOpenA, setModalOpenA ] = useState(false);
  const [ modalOpenR, setModalOpenR ] = useState(false);
  const [ id, setId ] = useState("");
  // const header = { password: process.env.REACT_APP_API_PASSWORD };
  const header = {
    'ngrok-skip-browser-warning': 'true',
    password: process.env.REACT_APP_API_PASSWORD,
  };

  const PlanTag = ({plan}) => {
    var C;
    if (plan === "躉繳") {
      C = '#24936E';
    } else if (plan.startsWith("定期定額")) {
      C = '#4566A2';
    } else {
      C = '#C95E5E';
    }
    return (
      <Tag color={C}>
        {plan}
      </Tag>
    );
  }

  // Modal
  const showModalA = (id) => {
    setModalOpenA(true);
    setId(id);
    // console.log(`id: ${id}`);
  };
  const showModalR = (id) => {
    setModalOpenR(true);
    setId(id);
    // console.log(`id: ${id}`);
  };

  const handleApprove = () => {
    post(`${process.env.REACT_APP_API_URL}/plutus/vip/confirm`, header, { lineUserId: id })
    .then((resp)=>{
      if (resp.data.status === 'success') {
        get(`${process.env.REACT_APP_API_URL}/plutus/vip/waitingList`, header)
        .then((resp)=>{
          setListData(resp.data.data);
        })
        .catch((errorMsg)=>{
          console.log(errorMsg);
        });
      } else {
        window.toast.error("發生錯誤");
      }
    })
    .catch((errorMsg)=>{
      console.log(errorMsg);
    });
    setModalOpenA(false);
  };
  const handleRemove = () => {
    post(`${process.env.REACT_APP_API_URL}/plutus/vip/removeWaiting`, header, { lineUserId: id })
    .then((resp)=>{
      if (resp.data.status === 'success') {
        get(`${process.env.REACT_APP_API_URL}/plutus/vip/waitingList`, header)
        .then((resp)=>{
          setListData(resp.data.data);
        })
        .catch((errorMsg)=>{
          console.log(errorMsg);
        });
      } else {
        window.toast.error("發生錯誤");
      }
      setModalOpenR(false);
    })
    .catch((errorMsg)=>{
      console.log(errorMsg);
    });
  };
  const handleCancelA = () => {
    setModalOpenA(false);
    setId("");
  };
  const handleCancelR = () => {
    setModalOpenR(false);
    setId("");
  };

  const Actions = ({lineId}) => {
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
        <Button
          type="default"
          onClick={() => {navigate('/memberInfo', { state: { id: lineId, detail: false, overview: false, remove: false  } });}}
        >
          {<ZoomInOutlined />}
        </Button>
        <Button key="edit" type="primary" onClick={() => {showModalA(lineId)}}>
          {<CheckCircleOutlined />}
        </Button>
        <Button key="remove" type="primary" onClick={() => {showModalR(lineId)}} danger>
          {<CloseCircleOutlined />}
        </Button>
      </div>
    );
  };

  if (isAdmin === true) {
    let header = {
      'ngrok-skip-browser-warning': 'true',
      password: process.env.REACT_APP_API_PASSWORD,
    };
    if (listData === undefined) {
      get(`${process.env.REACT_APP_API_URL}/plutus/vip/waitingList`, header)
      .then((resp)=>{
        setListData(resp.data.data);
      })
      .catch((errorMsg)=>{
        console.log(errorMsg);
      });
    }
  }


  return (
    <>
      <Modal title="調整會員方案" open={modalOpenA} onCancel={handleCancelA}
        footer={[
          <Button key="cancel" type="default" onClick={handleCancelA}>
            取消
          </Button>,
          <Button key="adjust" type="primary" onClick={handleApprove}>
            確認收款
          </Button>,
        ]}
      >
        <p>請確定已收到此會員費用</p>
      </Modal>

      <Modal title="確認移除此會員" open={modalOpenR} onCancel={handleCancelR}
        footer={[
          <Button key="cancel" type="default" onClick={handleCancelR}>
            取消
          </Button>,
          <Button key="remove" type="primary" onClick={handleRemove} danger>
            確認移除
          </Button>,
        ]}
      >
        <p>確定要移除此申請者嗎？</p>
      </Modal>

      <List
        pagination={{
          position: 'bottom',
          align: 'center',
          hideOnSinglePage: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '15', '20', '30'],
          defaultPageSize: 10,
        }}
        bordered
        itemLayout="horizontal"
        dataSource={ isAdmin ? listData : [] }
        renderItem={(item, index) => (
          <List.Item actions={[<Actions lineId={item.lineUserId}/>]} style={{ padding: 12 }}>
            <List.Item.Meta
              avatar={<Avatar size={"large"} src={item.linePFP}/>}
              title={item.lineUserName + '（' + item.userName +'）'}
              description={
                <>
                <PlanTag plan={item.plan}/>
                <p>{item.paymentMethod+'：'+item.planAmount+'U'}</p>
                <p>{item.startDate.replace(/-/g, '/')+'申請'}</p>
                <p>{item.salesName}</p>
                </>
              }
            />
          </List.Item>
        )}
      />
    </>
  );
}

export default App;