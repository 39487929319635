import { Avatar, List, Button, Modal, Tag } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import { CloseCircleOutlined, CheckCircleOutlined, ZoomInOutlined } from '@ant-design/icons';
import { get, post } from '../../util/io';
import { useNavigate } from "react-router-dom";

const App = ({isAdmin}) => {
  const navigate = useNavigate();
  const [ listData, setListData ] = useState(undefined);
  const [ modalOpenA, setModalOpenA ] = useState(false);
  const [ modalOpenR, setModalOpenR ] = useState(false);
  const [ id, setId ] = useState("");
  // const header = { password: process.env.REACT_APP_API_PASSWORD };
  const header = {
    'ngrok-skip-browser-warning': 'true',
    password: process.env.REACT_APP_API_PASSWORD,
  };

  const PlanTag = ({plan}) => {
    var C;
    if (plan === "躉繳") {
      C = '#24936E';
    } else if (plan.startsWith("定期定額")) {
      C = '#4566A2';
    } else {
      C = '#C95E5E';
    }
    return (
      <Tag color={C}>
        {plan}
      </Tag>
    );
  }

  // Modal
  const showModalA = (id) => {
    setModalOpenA(true);
    setId(id);
  };
  const showModalR = (id) => {
    setModalOpenR(true);
    setId(id);
  };

  const handleApprove = () => {
    post(`${process.env.REACT_APP_API_URL}/plutus/vip/updatePay`, header, { lineUserId: id })
    .then((resp)=>{
      if (resp.data.status === 'success') {
        get(`${process.env.REACT_APP_API_URL}/plutus/vip/toBeCharge`, header)
        .then((resp)=>{
          setListData(resp.data.data);
        })
        .catch((errorMsg)=>{
          console.log(errorMsg);
        });
      } else {
        window.toast.error("發生錯誤");
      }
      setModalOpenA(false);
    })
    .catch((errorMsg)=>{
      console.log(errorMsg);
    });
  };
  const handleRemove = () => {
    post(`${process.env.REACT_APP_API_URL}/plutus/vip/removeUser`, header, { lineUserId: id })
    .then((resp)=>{
      if (resp.data.status === 'success') {
        get(`${process.env.REACT_APP_API_URL}/plutus/vip/toBeCharge`, header)
        .then((resp)=>{
          setListData(resp.data.data);
        })
        .catch((errorMsg)=>{
          console.log(errorMsg);
        });
      } else {
        window.toast.error("發生錯誤");
      }
      setModalOpenR(false);
    })
    .catch((errorMsg)=>{
      console.log(errorMsg);
    });
  };
  const handleCancelA = () => {
    setModalOpenA(false);
    setId("");
  };
  const handleCancelR = () => {
    setModalOpenR(false);
    setId("");
  };

  // const paydayColor = (due) => {
  //   const now = Number(moment( Date.now() ).tz("Asia/Taipei").format("X"));
  //   const dueTS = Number(moment(`${ due } 23:59:59`).tz("Asia/Taipei").format("X"));
  //   if (now > dueTS) {
  //     return '#ff4d4f';
  //   } else {
  //     return 'rgba(0, 0, 0, 0.45)';
  //   }
  // }


  const Actions = ({lineId}) => {
    return (
      <>
        <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
          <Button
            type="default"
            onClick={() => {navigate('/memberInfo', { state: { id: lineId, detail: true, overview: false, remove: false } });}}
          >
            {<ZoomInOutlined />}
          </Button>
          <Button key="edit" type="primary" onClick={() => {showModalA(lineId)}}>
            {<CheckCircleOutlined />}
          </Button>
          <Button key="remove" type="primary" onClick={() => {showModalR(lineId)}} danger>
            {<CloseCircleOutlined />}
          </Button>
        </div>
      </>
    );
  };

  if (isAdmin === true) {
    let header = {
      'ngrok-skip-browser-warning': 'true',
      password: process.env.REACT_APP_API_PASSWORD,
    };
    if (listData === undefined) {
      get(`${process.env.REACT_APP_API_URL}/plutus/vip/toBeCharge`, header)
      .then((resp)=>{
        setListData(resp.data.data);
      })
      .catch((errorMsg)=>{
        console.log(errorMsg);
      });
    }
  }

  return (
    <>
      <Modal title="確認收到費用" open={modalOpenA} onCancel={handleCancelA}
        footer={[
          <Button key="cancel" type="default" onClick={handleCancelA}>
            取消
          </Button>,
          <Button key="confirm" type="primary" onClick={handleApprove}>
            確認收款
          </Button>,
        ]}
      >
        <p>請確定已收到此會員費用</p>
      </Modal>

      <Modal title="確認移除此會員" open={modalOpenR} onCancel={handleCancelR}
        footer={[
          <Button key="cancel" type="default" onClick={handleCancelR}>
            取消
          </Button>,
          <Button key="remove" type="primary" onClick={handleRemove} danger>
            確認移除
          </Button>,
        ]}
      >
        <p>確定要移除此會員身分嗎？</p>
      </Modal>

      <List
        // style={{ width: '150%'}}
        pagination={{
          position: 'bottom',
          align: 'center',
          // hideOnSinglePage: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '15', '30', '70'],
          defaultPageSize: 10,
        }}
        bordered
        itemLayout="horizontal"
        dataSource={ isAdmin ? listData : [] }
        renderItem={(item, index) => (
          <List.Item actions={[<Actions lineId={item.lineUserId}/>]} style={{ padding: 12 }}>
            <List.Item.Meta
              avatar={<Avatar size={"large"} src={item.linePFP}/>}
              title={item.lineUserName + '（' + item.userName +'）'}
              description={
                <>
                  <PlanTag plan={item.plan}/>
                  <p>{item.paymentMethod+'：'+item.planAmount+'U'}</p>
                  <p>{'應繳：'+item.planAmount+'U'}</p>
                  <p>{item.salesName}</p>
                </>
              }
            />
          </List.Item>
        )}
      />
    </>
  );
}

export default App;