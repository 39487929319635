import { Button } from 'antd';
import React, { useState } from 'react';
import { get, post } from '../../util/io';
import moment from 'moment-timezone';
import * as dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
dayjs().format();

const Output = ({isAdmin}) => {
  const [ userData, setUserData ] = useState(undefined);
  // const header = { password: process.env.REACT_APP_API_PASSWORD };
  const header = {
    'ngrok-skip-browser-warning': 'true',
    password: process.env.REACT_APP_API_PASSWORD,
  };
  if (isAdmin === true) {
    if (userData === undefined) {
      get(`${process.env.REACT_APP_API_URL}/plutus/vip/allMember`, header)
      .then((resp)=>{
        setUserData(resp.data.data);
      })
      .catch((errorMsg)=>{
        console.log(errorMsg);
      });
    }
  } 

  const outputMemeberData = () => {
    const ws = XLSX.utils.json_to_sheet(userData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Members');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    saveAs(data, `${moment().format('YYYY-MM-DD')}會員資料.xlsx`);
  };
  return (
    <>
      <Button key="outputFile" type="primary" onClick={outputMemeberData}>
        輸出會員資料
      </Button>
    </>
  );
}

export default Output;