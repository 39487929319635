// import * as dotenv from 'dotenv';
import process from 'process';
import React, { useState, useEffect } from 'react';
import styles from "./index.module.scss";
import { Input, Space, DatePicker, Form, Button, Select, Menu, Modal } from 'antd';

import { useNavigate, useLocation, useParams } from "react-router-dom";
import { get, post } from '../../util/io';
import liff from "@line/liff";
import { det } from 'mathjs';

const MemberInfo = () => {
  const navigate = useNavigate();
  const [ isAdmin, setIsAdmin ] = useState(false);
  const userProfile = JSON.parse(localStorage.getItem("userProfile"));
  
  let userAgentString = navigator.userAgent;
  let isLIFF = userAgentString.indexOf("LIFF") > -1;
  
  useEffect(() => {
    const adm = JSON.parse(process.env.REACT_APP_ADMIN) || { admins: [] };
    let array = [...adm.admins];
    if (array.includes(userProfile.userId)) {
      setIsAdmin(true);
    }
    console.log(`isAdmin: ${isAdmin}`);
  }, [userProfile, isAdmin]);

  const location = useLocation();
  const lineUserId = location.state?.id;
  const detail = location.state?.detail;
  const overview = location.state?.overview;
  const remove = location.state?.remove;

  let header;
  // const { id } = useParams();
  const [ userData, setUserData ] = useState(undefined);
  const [ modalOpenA, setModalOpenA ] = useState(false);
  const [ modalOpenE, setModalOpenE ] = useState(false);
  const [ modalOpenR, setModalOpenR ] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    address: '',
    idNumber: '',
    date: '',
    sale: '',
  });

  const [profileForm] = Form.useForm();
  const [planForm] = Form.useForm();
  const [recordForm] = Form.useForm();
  const [interestForm] = Form.useForm();

  if (isAdmin === true) {
    header = {
      'ngrok-skip-browser-warning': 'true',
      password: process.env.REACT_APP_API_PASSWORD,
    };
  }

  // Modal
  const showModalA = () => {
    setModalOpenA(true);
  };
  const showModalE = () => {
    setModalOpenE(true);
  };
  const showModalR = () => {
    setModalOpenR(true);
  };

  const handleApprove = () => {
    let apiURL = !detail ? `${process.env.REACT_APP_API_URL}/plutus/vip/confirm` : `${process.env.REACT_APP_API_URL}/plutus/vip/updatePay`;
    let body = { lineUserId: lineUserId };
    post(apiURL, header, body)
      .then((resp) => {
        if (resp.data.status !== 'success') {
          window.toast.error("發生錯誤");
        }
      })
      .catch((errorMsg) => {
        console.log(errorMsg);
      });
    setModalOpenA(false);
    navigate('/backstage');
  };

  const handleAdjust = () => {
    let apiURL = `${process.env.REACT_APP_API_URL}/plutus/vip/updatePlan`;
    let body = { lineUserId: lineUserId };
    post(apiURL, header, body)
      .then((resp) => {
        if (resp.data.status !== 'success') {
          window.toast.error("發生錯誤");
        }
      })
      .catch((errorMsg) => {
        console.log(errorMsg);
      });
    setModalOpenE(false);
    navigate('/backstage');
  };

  const handleRemove = () => {
    let apiURL = !detail ? `${process.env.REACT_APP_API_URL}/plutus/vip/removeWaiting` : `${process.env.REACT_APP_API_URL}/plutus/vip/removeUser`;
    let body = { lineUserId: lineUserId };

    post(apiURL, header, body)
      .then((resp) => {
        if (resp.data.status !== 'success') {
          window.toast.error("發生錯誤");
        }
      })
      .catch((errorMsg) => {
        console.log(errorMsg);
      });
    setModalOpenR(false);
    navigate('/backstage');
  };

  const handleCancelA = () => {
    setModalOpenA(false);
  };
  const handleCancelE = () => {
    setModalOpenE(false);
  };
  const handleCancelR = () => {
    setModalOpenR(false);
  };



  if (isAdmin === true && !overview) {
    if (userData === undefined) {
      get(`${process.env.REACT_APP_API_URL}/plutus/vip/waitingList`, header)
      .then((resp)=>{
        setUserData(resp.data.data);
      })
      .catch((errorMsg)=>{
        console.log(errorMsg);
      });
    }
  }

  if (isAdmin === true && detail) {
    let header = {
      'ngrok-skip-browser-warning': 'true',
      password: process.env.REACT_APP_API_PASSWORD,
    };

    if (overview) {
      if (userData === undefined) {
        get(`${process.env.REACT_APP_API_URL}/plutus/vip/allMember`, header)
        .then((resp)=>{
          setUserData(resp.data.data);
        })
        .catch((errorMsg)=>{
          console.log(errorMsg);
        });
      }
    } else {
      if (userData === undefined) {
        get(`${process.env.REACT_APP_API_URL}/plutus/vip/toBeCharge`, header)
        .then((resp)=>{
          setUserData(resp.data.data);
        })
        .catch((errorMsg)=>{
          console.log(errorMsg);
        });
      }
    }
  }

  if (isAdmin === true && remove) {
    if (userData === undefined) {
      get(`${process.env.REACT_APP_API_URL}/plutus/vip/removeList`, header)
      .then((resp)=>{
        setUserData(resp.data.data);
      })
      .catch((errorMsg)=>{
        console.log(errorMsg);
      });
    }
  }

  useEffect(() => {
    if (userData !== undefined) {
      const result = userData.find(element => element.lineUserId === lineUserId);
      if (result) {
        profileForm.setFieldsValue({
          name: result.userName,
          phone: result.phone,
          address: result.address,
          idNumber: result.idCard,
        });
        planForm.setFieldsValue({
          plan: result.plan,
          payment: result.paymentMethod || '-',
          dividend: result.annualPercentageRate + '%' || '-',
          period: result.term + '年' || '-',
          amount: result.planAmount + ' U ' || '-',
          date: result.startDate.replace(/-/g, '/') + ' ~ ' + result.endDate.replace(/-/g, '/') || '-',
          sale: result.salesName || '-',
        });
        if (detail) {
          recordForm.setFieldsValue({
            previousPayment: result.lastPayday ? result.lastPayday.replace(/-/g, '/') : '-',
            nextPayment: result.nextPayday ? result.nextPayday.replace(/-/g, '/') : '-',
            nextAmount: result.nextPaymentAmount + ' U' || '-',
            accumulatedAmount: result.cumulativePaymentAmount + ' U' || '-',
            target: result.targetPaymentAmount + ' U' || '-',
            extension: result.extension || '-',
          });
          interestForm.setFieldsValue({
            previousPayday: result.lastInterestPayday ? result.lastInterestPayday.replace(/-/g, '/') : '-',
            nextPayday: result.nextInterestPayday ? result.nextInterestPayday.replace(/-/g, '/') : '-',
            nextInterestAmount: result.nextInterestAmount + ' U' || '-',
            accumulatedInterestAmount: result.cumulativeInterestAmount + ' U' || '-',
            totalInterestAmount: result.totalInterestAmount + ' U' || '-',
          });
        }
      } else {
        setFormData({
          name: '無資料',
          phone: '',
          address: '',
          idNumber: '',
          date: '',
          sale: '',
        });
      }
    }
  }, [userData]);

  const profileTab = [
    {
      label: '個人資料',
      key: 'profile',
      style: { padding: 0 },
    },
  ];
  const planTab = [
    {
      label: '方案選擇',
      key: 'plan',
      style: { padding: 0 }
    },
  ];
  const recordTab = [
    {
      label: '繳費紀錄',
      key: 'record',
      style: { padding: 0 }
    },
  ];
  const interestTab = [
    {
      label: '利息紀錄',
      key: 'interest',
      style: { padding: 0 }
    },
  ];

  return (
    <>
      <Modal title="審核會員身份" open={modalOpenA} onCancel={handleCancelA}
        footer={[
          <Button key="cancel" type="default" onClick={handleCancelA}>
            取消
          </Button>,
          <Button key="approve" type="primary" onClick={handleApprove}>
            審核通過
          </Button>,
        ]}
      >
        <p>請確認已收到費用</p>
      </Modal>
      <Modal title="修改資料申請" open={modalOpenE} onCancel={handleCancelE}
        footer={[
          <Button key="cancel" type="default" onClick={handleCancelE}>
            取消
          </Button>,
          <Button key="adjust" type="primary" onClick={handleAdjust}>
            送出申請
          </Button>,
        ]}
      >
        <p>請確認需修改資料，並於群組回報需要調整的資料及原因</p>
      </Modal>
      <Modal title="確認移除此會員" open={modalOpenR} onCancel={handleCancelR}
        footer={[
          <Button key="cancel" type="default" onClick={handleCancelR}>
            取消
          </Button>,
          <Button key="remove" type="primary" onClick={handleRemove} danger>
            確認移除
          </Button>,
        ]}
      >
        <p>確定要移除此會員申請嗎？</p>
      </Modal>
    <div className={styles.main}>
      <div className={styles.header}>
        <h2>會員管理</h2>
        { isLIFF ? null : <p style={{ fontSize: '12px', color: 'white' }}>請使用line開啟此頁</p>}
      </div>
      <div className={styles.formContainer}>
        <>
          <Menu mode="horizontal" items={profileTab} selectedKeys={'profile'} style={{fontWeight: '700', fontSize: '14px'}} />
            <Form
              layout='inline'
              name="profile"
              form={profileForm}
              colon={false}
              labelCol={{ span: 8, style: { fontWeight: '700' } }} 
            >
              <Space direction="vertical" style={{ width: '100%' }}>
                <Form.Item
                  label={<span style={{ color: '#9E9E9E' }}>中文姓名</span>}
                  name="name"
                  disabled={true}
                  style={{color:'#9E9E9E'}}
                >
                  <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
                </Form.Item>
                <Form.Item
                  label={<span style={{ color: '#9E9E9E' }}>手機電話</span>}
                  name="phone"
                >
                  <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
                </Form.Item>
                <Form.Item
                  label={<span style={{ color: '#9E9E9E' }}>居住地址</span>}
                  name="address"
                >
                   <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
                </Form.Item>
                <Form.Item
                  label={<span style={{ color: '#9E9E9E' }}>身份證字號</span>}
                  name="idNumber"
                >
                  <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
                </Form.Item>
              </Space>
            </Form>
          <Menu mode="horizontal" items={planTab} selectedKeys={'plan'} style={{fontWeight: '700', fontSize: '14px' }} />
          <Form
            layout='inline'
            form={planForm}
            name="plan"
            colon={false}
            labelCol={{ span: 8, style: { fontWeight: '700' } }} 
          >
            <Space direction="vertical" style={{ width: '100%' }}>
            <Form.Item
              name="plan"
              label={<span style={{ color: '#9E9E9E' }}>所選方案</span>}
            >
              <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
            </Form.Item>

            <Form.Item
              name="payment"
              label={<span style={{ color: '#9E9E9E' }}>繳費方式</span>}
            >
              <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
            </Form.Item>
            <Form.Item
              name="dividend"
              label={<span style={{ color: '#9E9E9E' }}>年息</span>}
            >
              <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
            </Form.Item>
            <Form.Item
              name="period"
              label={<span style={{ color: '#9E9E9E' }}>年期 / 年限</span>}
            >
              <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
            </Form.Item>
            <Form.Item
              name="amount"
              label={<span style={{ color: '#9E9E9E' }}>方案金額</span>}
            >
              <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
            </Form.Item>
            <Form.Item name="date" label={<span style={{ color: '#9E9E9E' }}>方案日期</span>}>
              <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
            </Form.Item>
            <Form.Item
              name="sale"
              label={<span style={{ color: '#9E9E9E' }}>負責業務專員</span>}
            >
              <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
            </Form.Item>
            </Space>
          </Form>
          {detail && (
          <>
          <Menu mode="horizontal" items={recordTab} selectedKeys={'record'} style={{fontWeight: '700', fontSize: '14px' }} />
          <Form
            layout='inline'
            form={recordForm}
            name="plan"
            colon={false}
            labelCol={{ span: 8, style: { fontWeight: '700' } }} 
          >
            <Space direction="vertical" style={{ width: '100%' }}>
            <Form.Item
              name="previousPayment"
              label={<span style={{ color: '#9E9E9E' }}>上次繳費時間</span>}
            >
              <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
            </Form.Item>

            <Form.Item
              name="nextPayment"
              label={<span style={{ color: '#9E9E9E' }}>下次繳費時間</span>}
            >
              <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
            </Form.Item>
            <Form.Item
              name="nextAmount"
              label={<span style={{ color: '#9E9E9E' }}>下次繳費金額</span>}
            >
              <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
            </Form.Item>
            <Form.Item
              name="accumulatedAmount"
              label={<span style={{ color: '#9E9E9E' }}>累積已繳費</span>}
            >
              <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
            </Form.Item>
            <Form.Item
              name="target"
              label={<span style={{ color: '#9E9E9E' }}>繳費目標</span>}
            >
              <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
            </Form.Item>
            <Form.Item
              name="extension"
              label={<span style={{ color: '#9E9E9E' }}>申請展延</span>}
            >
              <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
            </Form.Item>
            </Space>
          </Form>
          </>
          )}
          {detail && (
          <>
          <Menu mode="horizontal" items={interestTab} selectedKeys={'interest'} style={{fontWeight: '700', fontSize: '14px' }} />
          <Form
            layout='inline'
            form={interestForm}
            name="interest"
            colon={false}
            labelCol={{ span: 8, style: { fontWeight: '700' } }} 
          >
            <Space direction="vertical" style={{ width: '100%' }}>
            <Form.Item
              name="previousPayday"
              label={<span style={{ color: '#9E9E9E' }}>上次發放時間</span>}
            >
              <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
            </Form.Item>

            <Form.Item
              name="nextPayday"
              label={<span style={{ color: '#9E9E9E' }}>下次發放時間</span>}
            >
              <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
            </Form.Item>
            <Form.Item
              name="nextInterestAmount"
              label={<span style={{ color: '#9E9E9E' }}>下次發放利息</span>}
            >
              <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
            </Form.Item>
            <Form.Item
              name="accumulatedInterestAmount"
              label={<span style={{ color: '#9E9E9E' }}>累積已發放</span>}
            >
              <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
            </Form.Item>
            <Form.Item
              name="totalInterestAmount"
              label={<span style={{ color: '#9E9E9E' }}>總發放利息</span>}
            >
              <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
            </Form.Item>
            </Space>
          </Form>
            </>
          )}
          { !remove && (
            <>
            {detail ? (
              <>
                { !overview && (
                <Button key="confirm" type="primary" onClick={() => {showModalA(lineUserId)}}>
                  確認收款
                </Button>
                )}
              <div style={{ display: "flex", gap: 8 }}>
                <Button key="edit" type="default" onClick={() => {showModalE(lineUserId)}} style={{flex: 1}}>
                  修改資料申請
                </Button>
                <Button key="remove" type="primary" onClick={() => {showModalR(lineUserId)}} danger style={{flex: 1}}>
                  解除會員
                </Button>
              </div>
              </>
            ) : (
              <>
              <div style={{ display: "flex", gap: 8 }}>
                <Button key="edit" type="primary" onClick={() => {showModalA(lineUserId)}} style={{flex: 1}}>
                  審核通過
                </Button>
                <Button key="remove" type="primary" onClick={() => {showModalR(lineUserId)}} danger style={{flex: 1}}>
                  審核不通過
                </Button>
              </div>
              </>
            )}
            </>
          )}
        </>
      </div>

      <footer>
      <p>
        技術支援 &copy; 2024 &nbsp;
        <a href="https://toriiitech.pse.is/co-wrote" target="_blank" rel="noopener noreferrer" style={{ color: "#24936E" }}>
           鳥居科技 Toriii
        </a>
      </p>
      </footer>
    </div>
    </>
  );
};

export default MemberInfo;