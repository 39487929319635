import { Avatar, List, Button, Modal, Tag } from "antd";
import { ZoomInOutlined, CloseCircleOutlined } from "@ant-design/icons";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { get, post } from "../../util/io";
import * as dayjs from "dayjs";
dayjs().format();

const App = ({ isAdmin }) => {
  const navigate = useNavigate();
  const [listData, setListData] = useState(undefined);
  const [modalOpenR, setModalOpenR] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const header = {
    "ngrok-skip-browser-warning": "true",
    password: process.env.REACT_APP_API_PASSWORD,
  };

  // Modal
  const showModalR = (index) => {
    setModalOpenR(true);
    setCurrentUser(index);
  };

  const handleRemove = () => {
    post(`${process.env.REACT_APP_API_URL}/plutus/vip/removeUser`, header, {
      lineUserId: currentUser,
    })
      .then((resp) => {
        if (resp.data.status === "success") {
          get(`${process.env.REACT_APP_API_URL}/plutus/vip/allMember`, header)
            .then((resp) => {
              setListData(resp.data.data);
              console.log("update all user data");
            })
            .catch((errorMsg) => {
              console.log(errorMsg);
            });
        } else {
          window.toast.error("發生錯誤");
        }

        setModalOpenR(false);
        setCurrentUser("");
      })
      .catch((errorMsg) => {
        console.log(errorMsg);
        setModalOpenR(false);
        setCurrentUser("");
      });
  };

  const handleCancelR = () => {
    setModalOpenR(false);
    setCurrentUser("");
  };

  const PlanTag = ({ plan }) => {
    var C;
    if (plan === "躉繳") {
      C = "#24936E";
    } else if (plan.startsWith("定期定額")) {
      C = "#4566A2";
    } else {
      C = "#C95E5E";
    }
    return <Tag color={C}>{plan}</Tag>;
  };

  const Actions = ({ index }) => {
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
        <Button
          type="default"
          onClick={() => {
            navigate("/memberInfo", {
              state: { id: index, detail: true, overview: true, remove: false },
            });
          }}
        >
          {<ZoomInOutlined />}
        </Button>
        <Button
          key="remove"
          type="primary"
          onClick={() => {
            showModalR(index);
          }}
          danger
        >
          {<CloseCircleOutlined />}
        </Button>
      </div>
    );
  };

  if (isAdmin === true) {
    let header = {
      "ngrok-skip-browser-warning": "true",
      password: process.env.REACT_APP_API_PASSWORD,
    };
    if (listData === undefined) {
      get(`${process.env.REACT_APP_API_URL}/plutus/vip/allMember`, header)
        .then((resp) => {
          setListData(resp.data.data);
        })
        .catch((errorMsg) => {
          console.log(errorMsg);
        });
    }
  }

  useEffect(() => {
    if (listData !== undefined) {
      get(`${process.env.REACT_APP_API_URL}/plutus/vip/allMember`, header)
        .then((resp) => {
          setListData(resp.data.data);
        })
        .catch((errorMsg) => {
          console.log(errorMsg);
        });
    }
  }, [listData]);

  return (
    <>
      <Modal
        title="確認移除此會員"
        open={modalOpenR}
        onCancel={handleCancelR}
        footer={[
          <Button key="cancel" type="default" onClick={handleCancelR}>
            取消
          </Button>,
          <Button key="remove" type="primary" onClick={handleRemove} danger>
            確認移除
          </Button>,
        ]}
      >
        <p>確定要移除此會員身分嗎？</p>
      </Modal>
      <List
        pagination={{
          position: "bottom",
          align: "center",
          hideOnSinglePage: true,
          pageSize: 5,
          onChange: (page) => {
            setCurrentPage(page);
          },
        }}
        bordered
        itemLayout="horizontal"
        dataSource={isAdmin ? listData : []}
        renderItem={(item, index) => {
          // const serialIndex = (currentPage - 1) * 5 + index;
          return (
            <List.Item
              actions={[<Actions index={item.lineUserId} />]}
              style={{ padding: 12 }}
            >
              <List.Item.Meta
                avatar={<Avatar size={"large"} src={item.linePFP} />}
                title={
                  <>
                    <p
                      style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item.lineUserName}
                    </p>
                    <p
                      style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item.userName}
                    </p>
                  </>
                }
                description={
                  <>
                    <PlanTag plan={item.plan} />
                    <p>{item.paymentMethod + "：" + item.planAmount + "U"}</p>
                    <p>
                      {item.startDate.replace(/-/g, "/") +
                        "-" +
                        item.endDate.replace(/-/g, "/")}
                    </p>
                    <p>{item.salesName}</p>
                  </>
                }
              />
            </List.Item>
          );
        }}
      />
    </>
  );
};

export default App;
