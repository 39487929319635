// import * as dotenv from 'dotenv';
// require('dotenv').config();
import process from 'process';
import React, { useState, useEffect } from 'react';
import styles from "./index.module.scss";
import { Input, Space, Form, Menu } from 'antd';
import { get, post } from '../../util/io';
import liff from "@line/liff";
import { useNavigate } from "react-router-dom";

const Member = () => {
  const navigate = useNavigate();
  const userProfile = JSON.parse(localStorage.getItem("userProfile"));
  let userAgentString = navigator.userAgent;
  let isLIFF = userAgentString.indexOf("LIFF") > -1;
  const [ userData, setUserData ] = useState(undefined);
  const [profileForm] = Form.useForm();
  const [planForm] = Form.useForm();
  const [recordForm] = Form.useForm();
  const [interestForm] = Form.useForm();

  const header = {
    'ngrok-skip-browser-warning': 'true',
    password: process.env.REACT_APP_API_PASSWORD,
  };

  if (userData === undefined) {
    get(`${process.env.REACT_APP_API_URL}/plutus/vip/allMember`, header)
    .then((resp)=>{
      setUserData(resp.data.data);
    })
    .catch((errorMsg)=>{
      console.log(errorMsg);
    });
  }

  useEffect(() => {
    if (userData !== undefined) {
      const result = userData.find(element => element.lineUserId === userProfile.userId);
      if (result) {
        profileForm.setFieldsValue({
          name: result.userName,
          phone: result.phone,
          address: result.address,
          idNumber: result.idCard,
        });
        planForm.setFieldsValue({
          plan: result.plan,
          payment: result.paymentMethod || '-',
          dividend: result.annualPercentageRate + '%' || '-',
          period: result.term + '年' || '-',
          amount: result.planAmount + ' U ' || '-',
          date: result.startDate.replace(/-/g, '/') + ' ~ ' + result.endDate.replace(/-/g, '/') || '-',
          sale: result.salesName || '-',
        });
        recordForm.setFieldsValue({
          previousPayment: result.lastPayday ? result.lastPayday.replace(/-/g, '/') : '-',
          nextPayment: result.nextPayday ? result.nextPayday.replace(/-/g, '/') : '-',
          nextAmount: result.nextPaymentAmount + ' U' || '-',
          accumulatedAmount: result.cumulativePaymentAmount + ' U' || '-',
          target: result.targetPaymentAmount + ' U' || '-',
          extension: result.extension || '-',
        });
        interestForm.setFieldsValue({
          previousPayday: result.lastInterestPayday ? result.lastInterestPayday.replace(/-/g, '/') : '-',
          nextPayday: result.nextInterestPayday ? result.nextInterestPayday.replace(/-/g, '/') : '-',
          nextInterestAmount: result.nextInterestAmount + ' U' || '-',
          accumulatedInterestAmount: result.cumulativeInterestAmount + ' U' || '-',
          totalInterestAmount: result.totalInterestAmount + ' U' || '-',
        });
      } else {
        navigate('/signup');
      }
    }
  }, [userData]);

  const profileTab = [
    {
      label: '個人資料',
      key: 'profile',
      style: { padding: 0 },
    },
  ];
  const planTab = [
    {
      label: '方案選擇',
      key: 'plan',
      style: { padding: 0 }
    },
  ];
  const recordTab = [
    {
      label: '繳費紀錄',
      key: 'record',
      style: { padding: 0 }
    },
  ];
  const interestTab = [
    {
      label: '利息紀錄',
      key: 'interest',
      style: { padding: 0 }
    },
  ];

  return (
    <>
    <div className={styles.main}>
      <div className={styles.header}>
        <h2>我的會員</h2>
        { isLIFF ? null : <p style={{ fontSize: '12px', color: 'white' }}>請使用line開啟此頁</p>}
      </div>
      <div className={styles.formContainer}>
        <>
          {/* <Menu mode="horizontal" items={profileTab} selectedKeys={'profile'} style={{fontWeight: '700', fontSize: '14px'}} />
            <Form
              layout='inline'
              name="profile"
              form={profileForm}
              labelCol={{ style: { fontWeight: '700' } }}
              style={{ width: '100%' }}
            >
              <Space direction="vertical" style={{ width: '100%' }}>
                <Form.Item
                  label="中文姓名"
                  name="name"
                >
                  <Input readOnly style={{ fontWeight: '600' }}/>
                </Form.Item>
                <Form.Item
                  label="手機電話"
                  name="phone"
                >
                  <Input readOnly style={{ fontWeight: '600' }}/>
                </Form.Item>
                <Form.Item
                  label="居住地址"
                  name="address"
                >
                   <Input readOnly style={{ fontWeight: '600' }}/>
                </Form.Item>
                <Form.Item
                  label="身份證字號"
                  name="idNumber"
                >
                  <Input readOnly style={{ fontWeight: '600' }}/>
                </Form.Item>
              </Space>
            </Form> */}
          <Menu mode="horizontal" items={planTab} selectedKeys={'plan'} style={{fontWeight: '700', fontSize: '14px' }} />
          <Form
            layout='inline'
            form={planForm}
            name="plan"
            colon={false}
            labelCol={{ span: 8, style: { fontWeight: '700' } }} 
          >
            <Space direction="vertical" style={{ width: '100%' }}>
            <Form.Item
              name="plan"
              label={<span style={{ color: '#9E9E9E' }}>所選方案</span>}
            >
              <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
            </Form.Item>

            <Form.Item
              name="payment"
              label={<span style={{ color: '#9E9E9E' }}>繳費方式</span>}
            >
              <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
            </Form.Item>
            <Form.Item
              name="dividend"
              label={<span style={{ color: '#9E9E9E' }}>年息</span>}
            >
              <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
            </Form.Item>
            <Form.Item
              name="period"
              label={<span style={{ color: '#9E9E9E' }}>年期 / 年限</span>}
            >
              <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
            </Form.Item>
            <Form.Item
              name="amount"
              label={<span style={{ color: '#9E9E9E' }}>方案金額</span>}
            >
              <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
            </Form.Item>
            <Form.Item name="date" label={<span style={{ color: '#9E9E9E' }}>方案日期</span>}>
              <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
            </Form.Item>
            <Form.Item
              name="sale"
              label={<span style={{ color: '#9E9E9E' }}>負責業務專員</span>}
            >
              <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
            </Form.Item>
            </Space>
          </Form>
          <Menu mode="horizontal" items={recordTab} selectedKeys={'record'} style={{fontWeight: '700', fontSize: '14px' }} />
          <Form
            layout='inline'
            form={recordForm}
            name="plan"
            colon={false}
            labelCol={{ span: 8, style: { fontWeight: '700' } }} 
          >
            <Space direction="vertical" style={{ width: '100%' }}>
            <Form.Item
              name="previousPayment"
              label={<span style={{ color: '#9E9E9E' }}>上次繳費時間</span>}
            >
              <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
            </Form.Item>

            <Form.Item
              name="nextPayment"
              label={<span style={{ color: '#9E9E9E' }}>下次繳費時間</span>}
            >
              <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
            </Form.Item>
            <Form.Item
              name="nextAmount"
              label={<span style={{ color: '#9E9E9E' }}>下次繳費金額</span>}
            >
              <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
            </Form.Item>
            <Form.Item
              name="accumulatedAmount"
              label={<span style={{ color: '#9E9E9E' }}>累積已繳費</span>}
            >
              <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
            </Form.Item>
            <Form.Item
              name="target"
              label={<span style={{ color: '#9E9E9E' }}>繳費目標</span>}
            >
              <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
            </Form.Item>
            <Form.Item
              name="extension"
              label={<span style={{ color: '#9E9E9E' }}>申請展延</span>}
            >
              <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
            </Form.Item>
            </Space>
          </Form>
          <Menu mode="horizontal" items={interestTab} selectedKeys={'interest'} style={{fontWeight: '700', fontSize: '14px' }} />
          <Form
            layout='inline'
            form={interestForm}
            name="interest"
            colon={false}
            labelCol={{ span: 8, style: { fontWeight: '700' } }} 
          >
            <Space direction="vertical" style={{ width: '100%' }}>
            <Form.Item
              name="previousPayday"
              label={<span style={{ color: '#9E9E9E' }}>上次發放時間</span>}
            >
              <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
            </Form.Item>

            <Form.Item
              name="nextPayday"
              label={<span style={{ color: '#9E9E9E' }}>下次發放時間</span>}
            >
              <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
            </Form.Item>
            <Form.Item
              name="nextInterestAmount"
              label={<span style={{ color: '#9E9E9E' }}>下次發放利息</span>}

            >
              <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
            </Form.Item>
            <Form.Item
              name="accumulatedInterestAmount"
              label={<span style={{ color: '#9E9E9E' }}>累積已發放</span>}
            >
              <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
            </Form.Item>
            <Form.Item
              name="totalInterestAmount"
              label={<span style={{ color: '#9E9E9E' }}>總發放利息</span>}

            >
              <Input readOnly disabled={true} style={{ fontWeight: '600' }}/>
            </Form.Item>
            </Space>
          </Form>
        </>
      </div>

      <footer>
      <p>
        技術支援 &copy; 2024 &nbsp;
        <a href="https://toriiitech.pse.is/co-wrote" target="_blank" rel="noopener noreferrer" style={{ color: "#24936E" }}>
           鳥居科技 Toriii
        </a>
      </p>
      </footer>
    </div>
    </>
  );
};
export default Member;