// import * as dotenv from 'dotenv';
import process from 'process';
import React, { useState, useEffect } from 'react';
// import styled from 'styled-components';
import styles from "./index.module.scss";
import { Input, Space, DatePicker, Form, Button, Select, Menu, Modal } from 'antd';
import { CheckCircleOutlined, CaretDownFilled } from '@ant-design/icons';

import { useNavigate } from "react-router-dom";
import { get, post } from '../../util/io';
import liff from "@line/liff";

const { Option } = Select;
const defaultCurrentUser = {
  member: false
}

const SignUp = () => {
  const navigate = useNavigate();
  const userProfile = JSON.parse(localStorage.getItem("userProfile"));
  let userAgentString = navigator.userAgent;
  let isLIFF = userAgentString.indexOf("LIFF") > -1;
  const [ userData, setUserData ] = useState(undefined);
  const [ withInputData, setWithInputData ] = useState(false);
  const [ currentUser, setCurrentUser ] = useState(defaultCurrentUser);
  const [ inputData, setInputData ] = useState({});
  const [ modalOpenS, setModalOpenS ] = useState(false);
  // const [ loading, setLoading ] = useState(false);
  const [profileForm] = Form.useForm();
  const [planForm] = Form.useForm();
  const [formValues, setFormValues] = useState({
    name: '',
    phone: '',
    address: '',
    idNumber: '',
    amount: '',
    startDate: '',
    endDate: '',
    sale: '',
  });
  
  const handleInputChange = (fieldName, value) => {
    setFormValues({
      ...formValues,
      [fieldName]: value,
    });
  };

  const header = {
    'ngrok-skip-browser-warning': 'true',
    password: process.env.REACT_APP_API_PASSWORD,
  };

  const done = () => {
    setModalOpenS(false);
    setInputData({
      lineUserId: userProfile.userId,
      linePFP: userProfile.pictureUrl,
      lineUserName: userProfile.displayName,
      startDate: planForm.getFieldValue('startDate').format('YYYY-MM-DD'),
      address: profileForm.getFieldValue('address'),
      idCard: profileForm.getFieldValue('idNumber'),
      phone: profileForm.getFieldValue('phone'),
      plan: planForm.getFieldValue('plan'),
      annualPercentageRate: planForm.getFieldValue('dividend'),
      paymentMethod: planForm.getFieldValue('payment'),
      term: planForm.getFieldValue('period'),
      endDate: planForm.getFieldValue('endDate').format('YYYY-MM-DD'),
      planAmount: planForm.getFieldValue('amount'),
      salesName: planForm.getFieldValue('sale'),
      userName: profileForm.getFieldValue('name'),
    });
    // console.log({
    //   lineUserId: userProfile.userId,
    //   linePFP: userProfile.pictureUrl,
    //   lineUserName: userProfile.displayName,
    //   startDate: planForm.getFieldValue('date')[0].format('YYYY-MM-DD'),
    //   address: profileForm.getFieldValue('address'),
    //   idCard: profileForm.getFieldValue('idNumber'),
    //   phone: profileForm.getFieldValue('phone'),
    //   plan: planForm.getFieldValue('plan'),
    //   annualPercentageRate: planForm.getFieldValue('dividend'),
    //   paymentMethod: planForm.getFieldValue('payment'),
    //   term: planForm.getFieldValue('period'),
    //   endDate: planForm.getFieldValue('date')[1].format('YYYY-MM-DD'),
    //   planAmount: planForm.getFieldValue('amount'),
    //   salesName: planForm.getFieldValue('sale'),
    //   userName: profileForm.getFieldValue('name'),
    // });
    setWithInputData(true);
    setCurrentUser(true);
  }

  if (userData === undefined) {
    get(`${process.env.REACT_APP_API_URL}/plutus/vip/allMember`, header)
    .then((resp)=>{
      setUserData(resp.data.data);
    })
    .catch((errorMsg)=>{
      console.log(errorMsg);
    });
  }

  useEffect(() => {
    if (userData !== undefined) {
      const result = userData.find(element => element.lineUserId === userProfile.userId);
      if (result) {
        navigate('/member');
        setCurrentUser({
          member: true
        });
      } else {
        setCurrentUser({
          member: false
        });
      }
    }
  }, [userData]);
  
  useEffect(() => {
    let header = {
      'ngrok-skip-browser-warning': 'true',
      password: process.env.REACT_APP_API_PASSWORD,
    };
    if (withInputData) {
      // console.log('inputData: ', inputData);
      post(`${process.env.REACT_APP_API_URL}/plutus/vip/signUp`, header, inputData)
      .then((resp) => {
        // setLoading(false);
        let status = resp.data.status;
        // console.log(status);

        if (status === 'success') {
          liff.sendMessages([
            {
              type: "text",
              text: "加入申請",
            },
          ])
          .then(() => {
            liff.closeWindow();
          });
          console.log("Good");
        } else {
          window.toast.error('系統錯誤，請稍候再試');
        }
      })
      .catch((errorMsg)=>{
        console.log(errorMsg);
      })
    }
  }, [inputData, withInputData]);

  const profileTab = [
    {
      label: '個人資料',
      key: 'profile',
      style: { padding: 0 },
    },
  ];
  const planTab = [
    {
      label: '方案選擇',
      key: 'plan',
      style: { padding: 0 }
    },
  ];

  const [paymentOptions, setPaymentOptions] = useState([]);
  const [amount, setAmout] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [dividendOptions, setDividendOptions] = useState([]);

  useEffect(() => {
    setPaymentOptions(['月繳', '季繳', '半年繳', '年繳']);
  }, []);

  // 根據年息及方案改變年限年份
  const handleDividendChange = (value) => {
    const currentPlan = planForm.getFieldValue('plan');
    if (currentPlan.startsWith('定期定額')) {
      let periodValue = '6';
      switch (value) {
        case '8':
          periodValue = '6';
          break;
        case '9':
          periodValue = '10';
          break;
        case '10':
          periodValue = '15';
          break;
        default:
          periodValue = '6';
      }
      planForm.setFieldsValue({ period: periodValue });
    } else if (currentPlan === '躉繳') {
      let periodValue = '10';
      if (amount <= 16667) {
        switch (value) {
          case '10':
            periodValue = '1';
            break;
          case '11':
            periodValue = '2';
            break;
          case '12':
            periodValue = '3';
            break;
          case '13':
            periodValue = '4';
            break;
          default:
            periodValue = '1';
        }
      } else if (amount >= 17000 && amount <= 33333) {
        switch (value) {
          case '11':
            periodValue = '1';
            break;
          case '12':
            periodValue = '2';
            break;
          case '13':
            periodValue = '3';
            break;
          case '14':
            periodValue = '4';
            break;
          default:
            periodValue = '1';
        }
      } else if (amount >= 33667) {
        switch (value) {
          case '12':
            periodValue = '1';
            break;
          case '13':
            periodValue = '2';
            break;
          case '14':
            periodValue = '3';
            break;
          case '15':
            periodValue = '4';
            break;
          default:
            periodValue = '1';
        }
      }
      planForm.setFieldsValue({ period: periodValue });
    }
  };

  // 根據繳費方式改變方案金額帶入數字(定期定額)
  const handlePaymentChange = (value) => {
    const currentPlan = planForm.getFieldValue('plan');
    let amountValue = '100';
    if (currentPlan === '定期定額 A') {
      switch (value) {
        case '月繳':
          amountValue = '100';
          break;
        case '季繳':
          amountValue = '300';
          break;
        case '半年繳':
          amountValue = '597';
          break;
        case '年繳':
          amountValue = '1188';
          break;
        default:
          amountValue = '1188';
      }
    } else if (currentPlan === '定期定額 B') {
      switch (value) {
        case '月繳':
          amountValue = '200';
          break;
        case '季繳':
          amountValue = '600';
          break;
        case '半年繳':
          amountValue = '1194';
          break;
        case '年繳':
          amountValue = '2376';
          break;
        default:
          amountValue = '2376';
      }
    } else if (currentPlan === '定期定額 C') {
      switch (value) {
        case '月繳':
          amountValue = '300';
          break;
        case '季繳':
          amountValue = '900';
          break;
        case '半年繳':
          amountValue = '1791';
          break;
        case '年繳':
          amountValue = '3564';
          break;
        default:
          amountValue = '3564';
      }
    } else if (currentPlan === '定期定額 D') {
      switch (value) {
        case '月繳':
          amountValue = '400';
          break;
        case '季繳':
          amountValue = '1200';
          break;
        case '半年繳':
          amountValue = '2388';
          break;
        case '年繳':
          amountValue = '4752';
          break;
        default:
          amountValue = '4752';
      }
    } else if (currentPlan === '定期定額 E') {
      switch (value) {
        case '月繳':
          amountValue = '500';
          break;
        case '季繳':
          amountValue = '1500';
          break;
        case '半年繳':
          amountValue = '2985';
          break;
        case '年繳':
          amountValue = '5940';
          break;
        default:
          amountValue = '5940';
      }
    }
    planForm.setFieldsValue({ amount: amountValue });
  };

  // 根據輸入方案金額更改年息選項(躉繳)
  const handleAmountChange = (value) => {
    let newDividendOptions = [];
    setAmout(value);
    if (selectedPlan === '躉繳') {
      if (value <= 16667) {
        newDividendOptions = ['10', '11', '12', '13'];
      } else if (value >= 17000 && value <= 33333) {
        newDividendOptions = ['11', '12', '13', '14'];
      } else if (value >= 33667) {
        newDividendOptions = ['12', '13', '14', '15'];
      }
      setDividendOptions(newDividendOptions);
    }
  
  };

  // 根據方案選擇改變繳費方式選項
  const handlePlanChange = (value) => {
    setSelectedPlan(value);
    switch (value) {
      case '躉繳':
        setPaymentOptions(['躉繳']);
        planForm.setFieldsValue({ payment: '躉繳'});
        break;
      case '定期定額 A':
        setPaymentOptions(['月繳', '季繳', '半年繳', '年繳']);
        setDividendOptions(['8', '9', '10']);
        planForm.setFieldsValue({ payment: '月繳', dividend: '8', period: '6' });
        break;
      case '定期定額 B':
        setPaymentOptions(['月繳', '季繳', '半年繳', '年繳']);
        setDividendOptions(['8', '9', '10']);
        planForm.setFieldsValue({ payment: '月繳', dividend: '8', period: '6'});
        break;
      case '定期定額 C':
        setPaymentOptions(['月繳', '季繳', '半年繳', '年繳']);
        setDividendOptions(['8', '9', '10']);
        planForm.setFieldsValue({ payment: '月繳', dividend: '8', period: '6' });
        break;
      case '定期定額 D':
        setPaymentOptions(['月繳', '季繳', '半年繳', '年繳']);
        setDividendOptions(['8', '9', '10']);
        planForm.setFieldsValue({ payment: '月繳', dividend: '8', period: '6' });
        break;
      case '定期定額 E':
        setPaymentOptions(['月繳', '季繳', '半年繳', '年繳']);
        setDividendOptions(['8', '9', '10']);
        planForm.setFieldsValue({ payment: '月繳', dividend: '8', period: '6' });
        break;
      case '既有會員':
        setPaymentOptions(['-']);
        planForm.setFieldsValue({ payment: '-', dividend: '-', period: '-', amount: '-'});
        break;
      default:
        setPaymentOptions(['月繳', '季繳', '半年繳', '年繳']);
        planForm.setFieldsValue({ payment: '月繳' });
    }
  };

  const checkPhoneValid = (phone) => {
    return /^\d{10}$/.test(phone);
  };
  const checkIdValid = (idNumber) => {
    return /^[A-Z]\d{9}$/.test(idNumber);
  };

  const onFinish = (values) => {
    showModalS();
  };

  const onFinishFailed = (errorInfo) => {
    window.toast.error("表格填寫有誤，請檢查後再試一次");
  };

  const handleSubmit = () => {
    Promise.all([
      profileForm.validateFields(),
      planForm.validateFields(),
    ]).then(values => {
      showModalS();
    }).catch(errorInfo => {
      window.toast.error("表格填寫有誤，請檢查後再試一次");
    });
  };

  const showModalS = () => {
    setModalOpenS(true);
  };
  const handleCancelS = () => {
    setModalOpenS(false);
  };

  return (
    <>
    <Modal title="送出申請" open={modalOpenS} onCancel={handleCancelS}
    footer={[
      <Button key="cancel" type="default" onClick={handleCancelS}>
        取消
      </Button>,
      <Button key="submit" type="primary" onClick={done}>
        送出申請
      </Button>,
    ]}
    >
      <p>送出後無法更改，請再次確認申請資料是否正確</p>
    </Modal>
    <div className={styles.main}>
      <div className={styles.header}>
        <h2>申請加入</h2>
        { isLIFF ? null : <p style={{ fontSize: '12px', color: 'white' }}>請使用line開啟此頁</p>}
      </div>
      <div className={styles.formContainer}>
        <>
          <Menu mode="horizontal" items={profileTab} selectedKeys={'profile'} style={{fontWeight: '700', fontSize: '14px'}} />
            <Form
              layout='inline'
              name="profile"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              form={profileForm}
              labelCol={{ span:8, style: { fontWeight: '700' } }} 
              initialValues={{
                name: '',
                phone: '',
                address: '',
                idNumber: '',
              }}
              requiredMark={false}
              // style={{ width: '100%' }}
            >
              <Space direction="vertical" style={{ width: '100%' }}>
                <Form.Item
                  label="中文姓名"
                  name="name"
                  validateStatus={formValues.name ? 'success' : 'error'}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: '請輸入姓名',
                    },
                  ]}
                >
                  <Input  
                    placeholder="陳家豪"
                    onChange={(e) => handleInputChange('name', e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  label="手機電話"
                  name="phone"
                  validateStatus={checkPhoneValid(formValues.phone) ? 'success' : 'error'}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: '請輸入手機號碼',
                    },
                    {
                      pattern: /^09\d{8}$/,
                      message: '須為10位數字且以09開頭',
                    }
                  ]}
                >
                  <Input 
                    placeholder="0912345678" 
                    maxLength={10}
                    onChange={(e) => {
                      let value = e.target.value;
                      value = value.replace(/[^\d]/g, '');
                      if (value.startsWith('09') && value.length <= 10) {
                        handleInputChange('phone', value);
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="居住地址"
                  name="address"
                  validateStatus={formValues.address ? 'success' : 'error'}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: '請輸入地址',
                    },
                  ]}
                >
                  <Input 
                    placeholder="台北市中正區八德路一段1號"
                    onChange={(e) => handleInputChange('address', e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  label="身份證字號"
                  name="idNumber"
                  validateStatus={checkIdValid(formValues.idNumber) ? 'success' : 'error'}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: '請輸入身分證字號',
                    },
                    {
                      pattern: /^[A-Z]\d{9}$/,
                      message: '須以大寫字母開頭，加上後面9位數字',
                    }
                  ]}
                >
                  <Input 
                    placeholder="A123456789"
                    maxLength={10}
                    onChange={(e) => handleInputChange('idNumber', e.target.value)}
                  />
                </Form.Item>
              </Space>
            </Form>
          <Menu mode="horizontal" items={planTab} selectedKeys={'plan'} style={{fontWeight: '700', fontSize: '14px' }} />
          <Form
            layout='inline'
            form={planForm}
            name="plan"
            labelCol={{ span: 8, style: { fontWeight: '700' } }} 
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            requiredMark={false}
          >
            <Space direction="vertical" style={{ width: '100%' }}>
            <Form.Item
              name="plan"
              label="所選方案"
              rules={[
                {
                  required: true,
                  message: '請選擇方案',
                },
              ]}
            >
              <Select
                style={{ width: '100%' }}
                onChange={handlePlanChange}
              >
                <Option value="躉繳">躉繳</Option>
                <Option value="定期定額 A">定期定額 A</Option>
                <Option value="定期定額 B">定期定額 B</Option>
                <Option value="定期定額 C">定期定額 C</Option>
                <Option value="定期定額 D">定期定額 D</Option>
                <Option value="定期定額 E">定期定額 E</Option>
                <Option value="既有會員">既有會員</Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="payment"
              label="繳費方式"
              rules={[
                {
                  required: true,
                  message: '請選擇繳費方式',
                },
              ]}
            >
                <Select
                  style={{ width: '100%' }}
                  onChange={handlePaymentChange}
                >
                  {paymentOptions.map(option => (
                    <Option key={option} value={option}>{option}</Option>
                  ))}
                </Select>
            </Form.Item>
            <Form.Item
              name="dividend"
              label="年息"
              rules={[
                {
                  required: true,
                  message: '請選擇年息',
                },
              ]}
            >
              <Select
                style={{ width: '100%' }}
                onChange={handleDividendChange}
              >
                {dividendOptions.map(option => (
                  <Option key={option} value={option}>{option}%</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="period"
              label="年期 / 年限"
            >
              <Input
                style={{ width: '100%' }}
                readOnly
                addonAfter="年"
              />
            </Form.Item>
            <Form.Item
              name="amount"
              label="方案金額"
              rules={[
                {
                  required: true,
                  message: '請輸入金額',
                },
                {
                  pattern: /^[1-9]\d*$/,
                  message: '金額必須是大於 0 的整數',
                }
              ]}
            >
              <Input 
                placeholder="16667"
                addonAfter="U"
                readOnly={selectedPlan !== '躉繳'}
                onChange={(e) => handleAmountChange(e.target.value)}
              />
            </Form.Item>
            <Form.Item 
              name="startDate"
              label="方案開始日期"
              rules={[
                {
                  required: true,
                  message: '請選擇方案開始日期!',
                },
              ]}
              validateStatus={formValues.startDate ? 'success' : 'error'}
            >
              <DatePicker onChange={(value) => handleInputChange('startDate', value)} style={{ width: '100%' }} />
            </Form.Item>
            {/* <div style={{ textAlign: 'center', margin: '10px 0' }}>
              <CaretDownFilled />
            </div> */}
            <Form.Item 
              name="endDate"
              label="方案結束日期"
              rules={[
                {
                  required: true,
                  message: '請選擇方案結束日期!',
                },
              ]}
              validateStatus={formValues.startDate ? 'success' : 'error'}
            >
              <DatePicker onChange={(value) => handleInputChange('endDate', value)} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              name="sale"
              label="負責業務專員"
              validateStatus={formValues.sale ? 'success' : 'error'}
            >
              <Select
                style={{ width: '100%' }}
                onChange={(value) => handleInputChange('sale', value)}
              >
                <Option value="">-</Option>
                <Option value="陳小明">陳小明</Option>
              </Select>
            </Form.Item>
            </Space>
          </Form>
          <Form.Item>
          <Button key="done" type="primary" htmlType="submit" onClick={handleSubmit} disabled={currentUser.member} style={{ width: '100%' }}  size='large'>
            送出申請 {<CheckCircleOutlined />}
          </Button>
          </Form.Item>

        </>
      </div>

      <footer>
      <p>
        技術支援 &copy; 2024 &nbsp;
        <a href="https://toriiitech.pse.is/co-wrote" target="_blank" rel="noopener noreferrer" style={{ color: "#24936E" }}>
           鳥居科技 Toriii
        </a>
      </p>
      </footer>
    </div>
    </>

  );
};
export default SignUp;