import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Button, ConfigProvider } from 'antd';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ConfigProvider
    autoInsertSpaceInButton={false}
    theme={{
      token: {
        colorPrimary: "#24936E",
      },
      components: {
        Button: {
          algorithm: true,
        }
      }
    }}
  >
    <App />
  </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
