import { Table, Avatar, Tag, Button, Modal } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import { get, post } from '../../util/io';
import { useNavigate } from "react-router-dom";
import MemberInfo from '../member/memberInfoWeb';
import * as dayjs from 'dayjs';
dayjs().format();

const Overview = ({isAdmin}) => {
  const navigate = useNavigate();
  const [ userData, setUserData ] = useState(undefined);
  const [ tableData, setTableData ] = useState(undefined);
  const [ modalOpenD, setModalOpenD ] = useState(false);
  const [ id, setId ] = useState("");

  const header = {
    'ngrok-skip-browser-warning': 'true',
    password: process.env.REACT_APP_API_PASSWORD,
  };

  const showModalD = (id) => {
    setModalOpenD(true);
    setId(id);
    // console.log(`id: ${id}`);
  };

  const handleCancelD = () => {
    setModalOpenD(false);
    setId("");
  };

  const columns = [
    {
      title: '用戶',
      dataIndex: 'user',
      width: '10%',
    },
    {
      title: '所選方案',
      dataIndex: 'plan',
      width: '10%',
      render: (text) => <PlanTag plan={text} />,
      filters: [
        {
          value: '躉繳',
          text: '躉繳',
        },
        {
          value: '定期定額 A',
          text: '定期定額 A',
        },
        {
          value: '定期定額 B',
          text: '定期定額 B',
        },
        {
          value: '定期定額 C',
          text: '定期定額 C',
        },
        {
          value: '定期定額 D',
          text: '定期定額 D',
        },
        {
          value: '定期定額 E',
          text: '定期定額 E',
        },
        {
          value: '既有會員',
          text: '既有會員',
        },
      ],
      onFilter: (value, record) => record.plan.indexOf(value) === 0,
    },
    {
      title: '繳費方式',
      dataIndex: 'payment',
      width: '10%',
      filters: [
        {
          value: '躉繳',
          text: '躉繳',
        },
        {
          value: '月繳',
          text: '月繳',
        },
        {
          value: '季繳',
          text: '季繳',
        },
        {
          value: '半年繳',
          text: '半年繳',
        },
        {
          value: '年繳',
          text: '年繳',
        },
      ],
      onFilter: (value, record) => record.payment.indexOf(value) === 0,
    },
    {
      title: '年息',
      dataIndex: 'dividend',
      width: '7%',
      filters: [
        {
          value: '8%',
          text: '8%',
        },
        {
          value: '9%',
          text: '9%',
        },
        {
          value: '10%',
          text: '10%',
        },
        {
          value: '11%',
          text: '11%',
        },
        {
          value: '12%',
          text: '12%',
        },
        {
          value: '13%',
          text: '13%',
        },
        {
          value: '14%',
          text: '14%',
        },
        {
          value: '15%',
          text: '15%',
        },
      ],
      onFilter: (value, record) => record.dividend.indexOf(value) === 0,
    },
    {
      title: '年期/年限',
      dataIndex: 'period',
      width: '11%',
      filters: [
        {
          value: '1年',
          text: '1年',
        },
        {
          value: '2年',
          text: '2年',
        },
        {
          value: '3年',
          text: '3年',
        },
        {
          value: '4年',
          text: '4年',
        },
        {
          value: '6年',
          text: '6年',
        },
        {
          value: '10年',
          text: '10年',
        },
        {
          value: '15年',
          text: '15年',
        },
      ],
      onFilter: (value, record) => record.period.indexOf(value) === 0,
    },
    {
      title: '方案金額',
      dataIndex: 'amount',
      width: '10%',
    },
    {
      title: '負責業務',
      dataIndex: 'sale',
      width: '10%',
      filters: [
        {
          value: '陳小明',
          text: '陳小明',
        },
      ],
      onFilter: (value, record) => record.sale.indexOf(value) === 0,
    },
    {
      title: '加入時間',
      dataIndex: 'startDate',
      width: '10%',
      sorter: (a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime(),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: '結束時間',
      dataIndex: 'endDate',
      width: '10%',
      sorter: (a, b) => new Date(a.endDate).getTime() - new Date(b.endDate).getTime(),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: '申請展延 月',
      dataIndex: 'extend',
      width: '12%',
      filters: [
        {
          value: '1月',
          text: '1月',
        },
        {
          value: '2月',
          text: '2月',
        },
        {
          value: '3月',
          text: '3月',
        },
        {
          value: '4月',
          text: '4月',
        },
        {
          value: '5月',
          text: '5月',
        },
        {
          value: '6月',
          text: '6月',
        },
      ],
      onFilter: (value, record) => record.extend.indexOf(value) === 0,
    },
    {
      title: '',
      dataIndex: 'actions',
      width: '3%',
    },
  ];

  const User = ({name, userName, imgUrl}) => {
    return (
      <div style={{ display: "flex", flexDirection: "row", gap: "12px", alignItems: "center" }}>
        <Avatar size={"large"} src={imgUrl}/>
        <div style={{display: "flex", flexDirection: "column", gap: "4px"}}>
        <p style={{ width: '80px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{name}</p>
        <p style={{ width: '80px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{userName}</p>
        </div>
      </div>
    );
  }

  const PlanTag = ({plan}) => {
    var C;
    if (plan === "躉繳") {
      C = '#24936E';
    } else if (plan.startsWith("定期定額")) {
      C = '#4566A2';
    } else {
      C = '#C95E5E';
    }
    return (
      <Tag color={C}>
        {plan}
      </Tag>
    );
  }

  const Actions = ({index}) => {
    return (
      <div style={{ display: "flex", gap: 4 }}>
        <Button
        type="text"
        onClick={() => {navigate('/memberInfo', { state: { id: index, detail: true, overview: true, remove: false } });}}
        >
          <p style={{ color: "var(--highlight-color-1)" }}>詳細資料</p>
        </Button>
        {/* <Button key="detail" type="text" onClick={() => {showModalD(index)}}>
          <p style={{ color: "var(--highlight-color-1)" }}>詳細資料</p>
        </Button> */}
      </div>
    );
  };

  // useEffect(() => {
    if (isAdmin === true) {
      if (userData === undefined) {
        get(`${process.env.REACT_APP_API_URL}/plutus/vip/allMember`, header)
        .then((resp)=>{
          setUserData(resp.data.data);
        })
        .catch((errorMsg)=>{
          console.log(errorMsg);
        });
      }
    }
  // }, [userData]);

  useEffect(() => {
    if (userData !== undefined) {
      let newData = userData.map((item, index) => {
        return {
          key: index,
          user: <User name={item.lineUserName} userName={item.userName} imgUrl={item.linePFP} />,
          plan: item.plan,
          payment: item.paymentMethod || '-',
          dividend: item.annualPercentageRate+'%' || '-',
          period: item.term + '年' || '-',
          amount: item.planAmount+' U' || '-',
          sale: item.salesName || '-',
          startDate: `${item.startDate.replace(/-/g, '/')}` || '-',
          endDate: `${item.endDate.replace(/-/g, '/')}` || '-',
          extend: item.extension ? item.extension + '月' : '-',
          actions: <Actions index={item.lineUserId}/>,
        }
      });

      setTableData([...newData]);
    }
  }, [userData]);

  return (
    <>
      <Modal title="詳細資料" open={modalOpenD} onCancel={handleCancelD}>
        <MemberInfo
          lineUserId={id}
          detail={true}
          overview={true}
          remove={false}
        />
      </Modal>
      <Table columns={columns} dataSource={tableData} style={{ fontWeight: "400" }}
        pagination={{
          position: ['bottomCenter'],
          showSizeChanger: true,
          pageSizeOptions: ['10', '15', '20', '30', '300'],
          defaultPageSize: 10
        }}
      />

    </>
  );
}

export default Overview;