import { Avatar, List, Button, Tag } from 'antd';
import { ZoomInOutlined } from '@ant-design/icons';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { get, post } from '../../util/io';
import * as dayjs from 'dayjs';
dayjs().format();

const RemoveList = ({isAdmin}) => {
  const navigate = useNavigate();
  const [ listData, setListData ] = useState(undefined);
  const [ currentPage, setCurrentPage] = useState(1);

  const PlanTag = ({plan}) => {
    var C;
    if (plan === "躉繳") {
      C = '#24936E';
    } else if (plan.startsWith("定期定額")) {
      C = '#4566A2';
    } else {
      C = '#C95E5E';
    }
    return (
      <Tag color={C}>
        {plan}
      </Tag>
    );
  }

  const Actions = ({index}) => {
    return (
      <div style={{ display: "flex", gap: 8 }}>
        <Button
        type="default"
        onClick={() => {navigate('/memberInfo', { state: { id: index, detail: true, overview: false, remove: true } });}}
        >
          {<ZoomInOutlined />}
        </Button>
      </div>
    );
  };

  if (isAdmin === true) {
    let header = {
      'ngrok-skip-browser-warning': 'true',
      password: process.env.REACT_APP_API_PASSWORD,
    };
    if (listData === undefined) {
      get(`${process.env.REACT_APP_API_URL}/plutus/vip/removeList`, header)
      .then((resp)=>{
        setListData(resp.data.data);
      })
      .catch((errorMsg)=>{
        console.log(errorMsg);
      });
    }
  } 

  return (
    <>
      <List
        pagination={{
          position: 'bottom',
          align: 'center',
          hideOnSinglePage: true,
          pageSize: 5,
          onChange: page => {
            setCurrentPage(page);
          },
        }}
        bordered
        itemLayout="horizontal"
        dataSource={ isAdmin ? listData : [] }
        renderItem={(item, index) => {
          // const serialIndex = (currentPage - 1) * 5 + index;
          return (
            <List.Item actions={[<Actions index={item.lineUserId}/>]} style={{ padding: 12 }}>
              <List.Item.Meta
                avatar={<Avatar size={"large"} src={item.linePFP}/>}
                title={
                  <p style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    {item.lineUserName + '（' + item.userName +'）'}
                  </p>
                }
                description={
                  <>
                    <PlanTag plan={item.plan}/>
                    <p>{item.paymentMethod+'：'+item.planAmount+'U'}</p>
                    <p>{item.startDate.replace(/-/g, '/')+'-'+item.endDate.replace(/-/g, '/')}</p>
                    <p>{item.salesName}</p>
                  </>
                }
              />
            </List.Item>
          );
        }}
      />
    </>
  );
}

export default RemoveList;